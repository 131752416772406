@import "tailwindcss/base";

@import "./layout.css";
@import "./fonts.css";
@import "./typo.css";
@import "./buttons.css";
@import "./transition.css";
@import "./animations.css";

@import "tailwindcss/components";

@import "./components.css";
/* @import "./custom-components.css"; */

@import "tailwindcss/utilities";
/* @import "./custom-utilities.css"; */