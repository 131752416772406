/* ibm-plex-sans-300 - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/ibm-plex-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/ibm-plex-sans-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/ibm-plex-sans-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/ibm-plex-sans-v9-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
  }

  /* ibm-plex-sans-300italic - latin */
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: url('/fonts/ibm-plex-sans-v9-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/ibm-plex-sans-v9-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/ibm-plex-sans-v9-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/ibm-plex-sans-v9-latin-300italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
  }

  /* ibm-plex-sans-regular - latin */
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/ibm-plex-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/ibm-plex-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/ibm-plex-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/ibm-plex-sans-v9-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
  }

  /* ibm-plex-sans-italic - latin */
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/ibm-plex-sans-v9-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/ibm-plex-sans-v9-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/ibm-plex-sans-v9-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/ibm-plex-sans-v9-latin-italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
  }

  /* ibm-plex-sans-500 - latin */
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/ibm-plex-sans-v9-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/ibm-plex-sans-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/ibm-plex-sans-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/ibm-plex-sans-v9-latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
  }

  /* ibm-plex-sans-500italic - latin */
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/ibm-plex-sans-v9-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/ibm-plex-sans-v9-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/ibm-plex-sans-v9-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('/fonts/ibm-plex-sans-v9-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/ibm-plex-sans-v9-latin-500italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
  }