strong {
    @apply font-medium text-zinc-900 dark:text-white;
}
em {
    @apply italic font-medium underline decoration-1 underline-offset-4
        decoration-blue-700
        dark:decoration-blue-300;
}
a {
    @apply transition
        underline decoration-1 underline-offset-2
        decoration-pink-500 dark:decoration-brand hover:decoration-blue-700
        decoration-2 dark:decoration-2
        hover:dark:decoration-white
        hover:text-black hover:dark:text-white;
}
