.badge {
    @apply inline-flex items-center
        px-3 py-1 leading-none rounded-lg
        bg-zinc-200 text-zinc-800
        text-sm;
}

.badge.error {
    @apply text-red-600 bg-red-200;
}
