.panel-slide-enter-active {
    transition: all 0.2s ease-out;
}

.panel-slide-leave-active {
  /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
}
.panel-slide-enter-from,
.panel-slide-leave-to {
  transform-origin: bottom;
  transform: translateY(10px) translateX(0px);
  opacity: 0;
  border-radius: 30px;
}

@screen md {
  .panel-slide-enter-from,
  .panel-slide-leave-to {

    transform: translateY(0px) translateX(10px);
  }
}

.fade-enter-active {
  transition: all 0.2s ease-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
