body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }

  html {
    height: -webkit-fill-available;
  }

.qrcode-stream-overlay,
.qrcode-stream-camera {
    @apply md:rounded-b-5xl;
}
