button, .button,
a.button {
    @apply inline-flex items-center
        relative px-3 md:px-4
        h-12 md:h-10

        bg-brand bg-opacity-100 hover:bg-brand-light
        active:bg-brand-light focus:bg-brand-light
        text-black hover:text-black rounded-lg
        no-underline

        ;
}

button i, .button i {
    @apply ml-2
}
button.is-loading,
.button.is-loading {
    @apply text-brand bg-brand-light;
}

button.is-loading::after,
.button.is-loading::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;

        border-radius: 50%;
        @apply inline-block border-t-black animate-spin;
        /* animation: button-loading-spinner 1s ease infinite; */
    }


    .cockpit-button {
        @apply
            transition h-14 w-14 md:h-12 md:w-12 rounded-5xl
            bg-white text-black dark:bg-slate-700 dark:text-slate-100
            border border-zinc-300/75 dark:border-slate-600/75
            text-xl no-underline
            flex items-center justify-center ;
    }

    .cockpit-button.met-schaduw {
        @apply shadow-md shadow-slate-600/25 dark:shadow-slate-900/60;
    }

    .dashboard-ribbon-button {
        @apply text-black dark:text-slate-100
            w-16 h-16
            text-2xl no-underline
            flex justify-center items-center
            transition
    }
    .is-pwa .dashboard-ribbon-button {
        @apply portrait:pb-0;
    }